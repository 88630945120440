import React, { Component } from 'react'
import Modeling3D from '../components/Modeling3D'
import Layout from '../layouts/Layout'

export default class modeling extends Component {
    render() {
        return (
            // <Layout>
                <Modeling3D/>
            // </Layout>
        )
    }
}
